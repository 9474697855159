import React, { Component } from "react"
import Draggable from '../../node_modules/react-draggable';
import AlbumSelector from './album-selector'
import AppStore from "../services/AppStore"
import menuIcon from "../images/menu_album.png"
import moveIcon from "../images/move.svg"
import UserService from "../services/UserService"
import "./album-tools.css"

class AlbumTools extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.resizeTimout = null
    this.userInfo = null
    this.state = {
      clientWidth: document.body.clientWidth,
      clientHeight: window.innerHeight
    }
    this.onResize = this.onResize.bind(this)
  }

  async componentDidMount() {
    this._isMounted = true
    this.userInfo = await UserService.getUserInfo()
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this.onResize)
  }

  onStopDragging (event, data) {
    AppStore.set(`ALBUM-TOOL-POSITION`, { x: data.x, y: data.y })
  }

  setEditMode () {
    this.props.selectAlbum()
  }

  onResize() {
    clearTimeout(this.resizeTimout)
    this.resizeTimout = setTimeout(() => {
      this.setState({
        clientWidth: document.body.clientWidth,
        clientHeight: window.innerHeight
        })}, 100)
  }

  render() {
    /*
    let stdDisplay = <div>[Pas d'album selectionne]</div>

    if (!this.props.selectedAlbums.length) {
      stdDisplay = <AlbumSelector 
        galleryMode={this.props.galleryMode}
        selectedAlbums={this.props.selectedAlbums}
        selectAlbum={this.props.selectAlbum}
        toggleAlbumToolMenu={this.props.toggleAlbumToolMenu}
      />
    }

    if ( this.props.selectedAlbums.length && !this.props.galleryMode) {
      stdDisplay = <>
        <div>
          <button onClick={() => this.setEditMode()}>Edit</button>
        </div>
        <div>
          { this.props.selectedAlbums.map(album => <div key={`alb_${album}`}>{album}</div>) }
        </div>
        </>
    }
    */

    let startPos = AppStore.get(`ALBUM-TOOL-POSITION`)
    let boxDims = {
      width: 305,
      height: 260
    }
    if (!startPos) {
      startPos = {x: 5, y: 120}
    }
    startPos.x = Math.min(startPos.x, this.state.clientWidth - boxDims.width)
    startPos.y = Math.min(startPos.y, this.state.clientHeight - boxDims.height)
    const bounds = { 
      top: 5,
      left: 5, 
      right: this.state.clientWidth - boxDims.width , 
      bottom: this.state.clientHeight - boxDims.height
    }

    return (
      <React.Fragment>
        <div className="album-tools-menu">
          <button onClick={this.props.toggleAlbumToolMenu}>
            <img src={menuIcon} alt="Outils d'albums" />
          </button>
        </div>
        <div className={`album-tools-selector-action-layer ${ (this.props.isOpen && !this.props.selectedAlbums.length && !this.props.galleryMode)? "is-open" : ""}`} onClick={this.props.toggleAlbumToolMenu}></div>
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={startPos}
          bounds={bounds}
          position={null}
          scale={1}
          onStop={this.onStopDragging}
        >
          <div className={`album-tools-wrapper ${this.props.isOpen ? "is-open" : ""}`}>
            <div className="handle">
              <span>Editeur d'Album</span>
              <div>
                <img src={moveIcon} alt="Positioneez l'editeur'" />
              </div>
            </div>
            <AlbumSelector 
              itemList={this.props.itemList}
              galleryMode={this.props.galleryMode}
              selectedAlbums={this.props.selectedAlbums}
              selectAlbum={this.props.selectAlbum}
              selectedDay={this.props.selectedDay}
              toggleAlbumToolMenu={this.props.toggleAlbumToolMenu}
              bulkAlbumItemsAction={this.props.bulkAlbumItemsAction}
            />
          </div>  
        </Draggable>  
      </React.Fragment>
    )
  }
}

export default AlbumTools
