import React, { Component } from "react"
import CalendarService from "../services/CalendarService"
import DisplayService from "../services/DisplayService"
import MediaService from "../services/MediaService"
import ViewSelector from "../components/view-selector"
import "./day-pager.css"

class DayPager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      buckets: "[]",
      currentDay: "",
      loading: true,
      prevDay: null,
      nextDay: null,
    }
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    if (
      nextProps.buckets !== "[]" &&
      nextProps.currentDay !== "" &&
      nextProps.buckets !== previousState.buckets
    ) {
      return {
        buckets: nextProps.buckets,
        currentDay: nextProps.currentDay,
        title: DisplayService.getFormattedDateFromKey(nextProps.currentDay),
      }
    }
    return null
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.buckets === this.props.buckets &&
      prevProps.currentDay === this.props.currentDay
    )
      return
    if (this.props.buckets === "[]") {
      return this.setState({
        prevDay: null,
        nextDay: null,
      })
    }

    let buckets = []
    try {
      buckets = JSON.parse(this.state.buckets)
    } catch (error) {
      console.error(error)
      return
    }

    let yearList = await MediaService.getYears()
    yearList = CalendarService.getYearList(yearList, buckets)
    const currentYearIndex = yearList.findIndex(
      el => el.year === +this.state.currentDay.substr(0, 4)
    )

    const daysData = await MediaService.getYearDays(
      this.state.currentDay.substr(0, 4)
    )
    const days = CalendarService.getDayList(daysData, buckets)
    const currentIndex = days.findIndex(el => el.day === this.state.currentDay)

    let prevDay = null
    let nextDay = null

    if (currentIndex === 0 || currentIndex === -1) {
      // check prev year for entries
      if (currentYearIndex < yearList.length - 1) {
        const prevDaysData = await MediaService.getYearDays(
          yearList[currentYearIndex + 1].year
        )
        const prevDays = CalendarService.getDayList(prevDaysData, buckets)
        prevDay = prevDays[prevDays.length - 1].day
      }
    } else {
      prevDay = days[currentIndex - 1].day
    }

    if (currentIndex === days.length - 1 || currentIndex === -1) {
      // check next year for entries
      if (currentYearIndex > 0) {
        const nextDaysData = await MediaService.getYearDays(
          yearList[currentYearIndex - 1].year
        )
        const nextDays = CalendarService.getDayList(nextDaysData, buckets)
        nextDay = nextDays[0].day
      }
    } else {
      nextDay = days[currentIndex + 1].day
    }

    this.setState({
      prevDay: prevDay,
      nextDay: nextDay,
    })
  }

  render() {
    let dayNav = []

    if (this.state.prevDay) {
      dayNav.push(
        <a key="prevDayNav" className="prev-day" href={`/day/${this.state.prevDay}`}>
          &lt;&lt; {DisplayService.getFormattedDateFromKey(this.state.prevDay)}
        </a>
      )
    }

    if (this.state.nextDay) {
      dayNav.push(
        <a key="nextDayNav" className="next-day" href={`/day/${this.state.nextDay}`}>
          {DisplayService.getFormattedDateFromKey(this.state.nextDay)} &gt;&gt;
        </a>
      )
    }

    return (
      <div className="day-header">
        <h1>{this.state.title}</h1>
        <div className="daynav no-select">{dayNav}</div>
        <ViewSelector 
          pageIsLoading={this.props.pageIsLoading}
          galleryMode={this.props.galleryMode} 
          handleToggleGalleryMode={this.props.handleToggleGalleryMode}  
        />
      </div>
    )
  }
}

export default DayPager
