import React, { Component } from "react"
import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from "../config"

import BucketService from "../services/BucketService"
import CookieService from "../services/CookieService"
import MediaService from "../services/MediaService"
import AlbumService from "../services/AlbumService"
import DisplayService from "../services/DisplayService"
import AppStore from "../services/AppStore"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BucketSelector from "../components/bucket-selector"
import MediaDisplay from "../components/media-display"
import MediaDetail from "../components/media-detail"
import DayPager from "../components/day-pager"
import AlbumTools from "../components/album-tool"

Amplify.configure(config)

class DayPage extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.isFullscreen = false
    this.selectedDay = null
    this.state = {
      loading: true,
      itemList: [],
      buckets: JSON.stringify([]),
      galleryMode: false,
      selectedMedias: [],
      selectedAlbums: [],
      showFullscreenButton: true,
      albumToolIsOpen: false
    }

    this.handleToggleGalleryMode = this.handleToggleGalleryMode.bind(this)
    this.handleBucketChange = this.handleBucketChange.bind(this)
    this.setToGallery = this.setToGallery.bind(this)
    this.selectMedia = this.selectMedia.bind(this)
    this.selectAlbum = this.selectAlbum.bind(this)
    this.deleteAction = this.deleteAction.bind(this)
    this.markAction = this.markAction.bind(this)
    this.albumItemsAction = this.albumItemsAction.bind(this)
    this.toggleAlbumToolMenu = this.toggleAlbumToolMenu.bind(this)
    this.bulkAlbumItemsAction = this.bulkAlbumItemsAction.bind(this)
  }

  async toggleAlbumToolMenu() {
    AppStore.set("ALBUM-TOOL-IS-OPEN", !this.state.albumToolIsOpen)
    const displayList = this.getDisplayList(this.state.itemList, JSON.parse(this.state.buckets))
    let selectedMedia = displayList.length ? displayList[0] : null
    if (this.state.selectedMedias.length) {
      selectedMedia = this.state.selectedMedias[0] 
    }
    const albumDayData = await this.getAlbumDayData(this.state.galleryMode, selectedMedia)
    if (this._isMounted) {
      this.setState({
        albumToolIsOpen: !this.state.albumToolIsOpen,
        selectedMedias: albumDayData.selectedMedias,
        selectedAlbums: albumDayData.selectedAlbums
      })
    }
  }

  async deleteAction(itemkey) {
    if (!itemkey) return
    const conf = window.confirm(
      "ATTENTION - z'êtes vraiment vraiment sur de vouloir effacer ce fichier?"
    )
    if (conf) {
      let split = itemkey.split("/")
      const bckt = split[0]
      split.shift()
      const res = await MediaService.deleteObject(bckt, split.join("/"))
      if (!res) {
        alert("there was a pb")
      } else {
        const filteredList = this.state.itemList.filter(
          item => item.key !== itemkey
        )
        if (this._isMounted) {
          this.setState({
            itemList: filteredList,
          })
        }
        // local storage cleanup
        AppStore.set(`CALENDAR-ITEMS-${this.selectedDay}`, filteredList)
        AppStore.removeMediaFromAlbums(itemkey)
      }
    }
  }

  async markAction(itemkey) {
    if (!itemkey) return
    let split = itemkey.split("/")
    const bckt = split[0]
    split.shift()
    const res = await MediaService.markObject(bckt, split.join("/"))
    if (!res) {
      alert("... ca n'a pas marche comme prevu... :( ")
    } else {
      alert("le fichier a ete copie dans le repertoire public_marked/")
    }
  }

  getDisplayList(itemList, buckets) {
    const list = itemList.filter(item => {
      const filtered = buckets.filter(
        bucket => bucket.name === item.key.split("/")[0]
      )
      return filtered.length === 1 && filtered[0].selected
    }).map(item => ({
      ...item,
      bucketName: buckets.filter(
        bucket => bucket.name === item.key.split("/")[0]
      )[0].display
    }))
    return list.sort((a, b) => {
      if (a.key < b.key) return -1
      if (a.key > b.key) return 1
      return 0
    })
  }

  async getAlbumDayData(galleryMode, selectedMedia) {
    let selectedMedias = []
    let selectedAlbums = []
    const dayData = await AlbumService.getDayAlbumItems(this.selectedDay)
    if (galleryMode) {
      // in this mode the selected Media controls the list of album (default to 1st displayed item on page load)
      // we get the first item that will be displayed as per bucket filter
      if (selectedMedia) selectedMedias =  [selectedMedia]
      selectedAlbums = dayData.filter(data => data.key === selectedMedia.key).map(data => data.albumId)
    } else {
      // in this mode the selected Album controls the list of media 'ticked'
      const selectedAlbumStore = AppStore.get('ALBUM-TOOL-SELECTED-ALBUMS')
      if (selectedAlbumStore !== null) {
        // we check if the selected album is still valid for the display (for EVENT, dates,..)
        let resetSelectedAlbum = false
        const albumData = AlbumService.extractIdData(selectedAlbumStore[0])
        if (albumData.type === 'EVENT') {
          if (new Date(albumData.startDate).getTime() > DisplayService.fromYYYYMMDD(this.selectedDay).getTime()
            || new Date(albumData.endDate).getTime() < DisplayService.fromYYYYMMDD(this.selectedDay).getTime()) {
              AppStore.unset('ALBUM-TOOL-SELECTED-ALBUMS')
              resetSelectedAlbum = true
            }
        }

        if (!resetSelectedAlbum) {
          selectedAlbums = selectedAlbumStore
          selectedMedias = dayData.filter(data => data.albumId === selectedAlbumStore[0]).map(data => {
            delete data.albumId
            return data
          })
        }
      }
    }

    return {
      selectedMedias,
      selectedAlbums
    }
  }

  handleBucketChange(evt) {
    let buckets = JSON.parse(this.state.buckets)
    buckets = buckets.map(bucket => {
      if (bucket.name === evt.target.dataset.name)
        return {
          ...bucket,
          selected: !evt.target.classList.contains("selected"),
        }
      return bucket
    })
    BucketService.setList(buckets)
    if (this._isMounted) this.setState({ buckets: JSON.stringify(buckets) })
  }

  async handleToggleGalleryMode() {
    let selectedMedias = []
    let selectedAlbums = []
    if (this.state.albumToolIsOpen) {
      const displayedList = this.getDisplayList(this.state.itemList, JSON.parse(this.state.buckets))
      const albumDayData = await this.getAlbumDayData(!this.state.galleryMode, displayedList.length ? displayedList[0] : null)
      selectedMedias = albumDayData.selectedMedias
      selectedAlbums = albumDayData.selectedAlbums
    }
    AppStore.set("CALENDAR-DAY-PAGE-GALLERY-MODE", !this.state.galleryMode)
    if (this._isMounted) {
      this.setState({
        galleryMode: !this.state.galleryMode,
        selectedMedias,
        selectedAlbums
      })
    }
  }

  async setToGallery(mediaKey) {
    // console.log('setToGallery mediaKey', mediaKey)
    // console.log('this.state.itemList', this.state.itemList)
    let selectedMedias = this.state.itemList.filter(media => media.key === mediaKey)
    let selectedAlbums = []
    if (this.state.albumToolIsOpen) {
      const albumDayData = await this.getAlbumDayData(true, selectedMedias[0])
      selectedMedias = albumDayData.selectedMedias
      selectedAlbums = albumDayData.selectedAlbums
    }
    AppStore.set("CALENDAR-DAY-PAGE-GALLERY-MODE", true)
    this.setState({
      galleryMode: true,
      selectedMedias,
      selectedAlbums
    })
  }

  async selectMedia(mediaKey) {
    // console.log('selectMedia', media)
    let selectedMedias = this.state.itemList.filter(media => media.key === mediaKey)
    let selectedAlbums = []
    if (this.state.albumToolIsOpen) {
      const albumDayData = await this.getAlbumDayData(this.state.galleryMode, selectedMedias[0])
      selectedMedias = albumDayData.selectedMedias
      selectedAlbums = albumDayData.selectedAlbums
    }
    // console.log('selectedMedias', selectedMedias)
    // console.log('selectedAlbums', selectedAlbums)
    if (this._isMounted) {
      this.setState({
        selectedMedias,
        selectedAlbums
      })
    }
  }

  async selectAlbum(albumId) {
    if (this.state.galleryMode) {
      // album selection is driving the action on media list (add/remove)
      AppStore.unset("ALBUM-TOOL-SELECTED-ALBUMS") // reset stored album (meaningless in gallery mdoe)

      let pos = this.state.selectedAlbums.indexOf(albumId)
      if (pos > -1) { // album is in the list -> remove
        await AlbumService.removeAlbumItem(this.state.selectedMedias[0].key, albumId)
        if (this._isMounted) {
          this.setState({
            selectedAlbums: this.state.selectedAlbums.filter(album => album !== albumId)
          })
        }
      } else { // album is not in the list -> add
        await AlbumService.addAlbumItem(this.state.selectedMedias[0], albumId)
        if (this._isMounted) {
          this.setState({
            selectedAlbums: this.state.selectedAlbums.concat(albumId)
          })
        }
      }
    } else {
      // Polaroid mode -> albumId can be null to indicate we reseting the selected album
      // album selection controls the displayed list of medias
      let selectedMedias
      let selectedAlbums
      if (!albumId) {
        AppStore.unset("ALBUM-TOOL-SELECTED-ALBUMS")
        selectedMedias = []
        selectedAlbums = []
      } else {
        AppStore.set("ALBUM-TOOL-SELECTED-ALBUMS", [albumId])
        selectedMedias = await AlbumService.getAlbumItems(albumId)
        selectedAlbums = [albumId]
      }
      if (this._isMounted) {
        this.setState({
          selectedAlbums,
          selectedMedias,
        })
      }
    }
  }

  async albumItemsAction(data) {
    let dayAlbumItems = null
    if (data.albumSelection) { // adding entry to album in polaroid mode
      dayAlbumItems = await AlbumService.addAlbumItem(data, this.state.selectedAlbums[0])
      if (this._isMounted) {
        this.setState({
          selectedMedias: dayAlbumItems.filter(item => item.albumId === this.state.selectedAlbums[0]) 
        })
      }
    } else { // removing entry from album in polaroid mode
      const mediaList = await AlbumService.removeAlbumItem(data.key, this.state.selectedAlbums[0])
      if (this._isMounted) {
        this.setState({
          selectedMedias: mediaList.dayAlbumItems.filter(item => item.albumId === this.state.selectedAlbums[0]) 
        })
      }
    }
  }

  async bulkAlbumItemsAction() {
    let itemList = []
    let buckets = []
    try {
      buckets = JSON.parse(this.state.buckets)
    } catch (error) {
      console.error(error)
      return
    }

    itemList = this.getDisplayList(this.state.itemList, buckets)
    // datalist will be all items that are not currrently selected on the page
    const dataList = []
    itemList.forEach(item => {
      if (this.state.selectedMedias.filter(item2 => (item2.key === item.key)).length === 0) dataList.push(item)
    })
    dataList.map(item => ({
      alb: this.state.selectedAlbums[0],
      day: +this.selectedDay,
      height: item.height,
      key: item.key,
      size: item.size,
      width: item.width
    }))
    const dayAlbumItemsDays = await AlbumService.bulkAddAlbumItem(dataList, this.state.selectedAlbums[0])
    const filtered = dayAlbumItemsDays.filter(item => (item.day - this.selectedDay === 0))
    if (!filtered.length) return
    if (this._isMounted) {
      this.setState({
        selectedMedias: filtered[0].dayAlbumItems.filter(item => item.albumId === this.state.selectedAlbums[0]) 
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async componentDidMount() {
    this._isMounted = true
    const cookies = await CookieService.set() // eslint-disable-line no-unused-vars

    const selectedDay = this.props["*"]
    // 'date param' validation
    const yearVal = selectedDay.substr(0, 4)
    const monthVal = selectedDay.substr(4, 2)
    const dayVal = selectedDay.substr(6, 2)
    let error = false
    if (+yearVal < 1900 || +yearVal > new Date().getFullYear()) error = true
    if (+monthVal < 1 || +monthVal > 12) error = true
    if (+dayVal < 1 || +dayVal > 31) error = true
    if (error) {
      window.location = '/'
    }

    this.selectedDay = selectedDay

    let galleryMode = AppStore.get("CALENDAR-DAY-PAGE-GALLERY-MODE")
    let albumToolIsOpen = AppStore.get("ALBUM-TOOL-IS-OPEN")

    if (this._isMounted) {
      this.setState({
        galleryMode,
        albumToolIsOpen: albumToolIsOpen ? true : false
      })
    }

    AppStore.set("CALENDAR-DAY-PAGE-TRACKING", selectedDay)
    const buckets = await BucketService.getList()

    let itemList = await MediaService.getDayMedias(selectedDay)

    let selectedMedias = []
    let selectedAlbums = []
    if (albumToolIsOpen) {
      const displayedList = this.getDisplayList(itemList, buckets)
      const albumDayData = await this.getAlbumDayData(galleryMode, displayedList.length ? displayedList[0] : null)
      selectedMedias = albumDayData.selectedMedias
      selectedAlbums = albumDayData.selectedAlbums
    }

    if (this._isMounted) {
      this.setState({
        loading: false,
        itemList,
        buckets: JSON.stringify(buckets),
        selectedAlbums,
        selectedMedias,
      })
    }
  }

  render() {
    let itemList = []
    let selectedMedia = ''
    if (!this.state.loading) {
      let buckets = []
      try {
        buckets = JSON.parse(this.state.buckets)
      } catch (error) {
        console.error(error)
        return
      }

      itemList = this.getDisplayList(this.state.itemList, buckets)
      itemList = itemList.map(item => {
        let albumSelection = false
        if (this.state.selectedMedias) {
          albumSelection = (this.state.selectedMedias.filter(item2 => (item2.key === item.key)).length > 0)
        }
        return { ...item, albumSelection }
      })

      if (itemList.length) selectedMedia = itemList[0].key
      if (this.state.selectedMedias.length) selectedMedia = this.state.selectedMedias[0].key
    }

    return (
      <Layout menu="DAY">
        <SEO title="Calendar Day" />
        <DayPager
          buckets={this.state.buckets}
          currentDay={this.selectedDay}
          galleryMode={this.state.galleryMode}
          pageIsLoading={this.state.loading}
          handleToggleGalleryMode={this.handleToggleGalleryMode}
        />
        <MediaDisplay
          loading={this.state.loading}
          itemList={itemList}
          galleryMode={this.state.galleryMode}
          setToGallery={this.setToGallery}
          selectMedia={this.selectMedia}
          selectedAlbums={this.state.selectedAlbums}
          markAction={this.markAction}
          deleteAction={this.deleteAction}
          albumItemsAction={this.albumItemsAction}
          albumToolIsOpen={this.state.albumToolIsOpen}
        />
        {this.state.galleryMode && !this.state.albumToolIsOpen && selectedMedia ? <MediaDetail selectedMedia={selectedMedia} /> : ''}
        <BucketSelector
          buckets={this.state.buckets}
          handleBucketChange={this.handleBucketChange}
        />
        <AlbumTools 
          itemList={itemList}
          galleryMode={this.state.galleryMode}
          selectedMedias={this.state.selectedMedias}
          selectedAlbums={this.state.selectedAlbums}
          selectedDay={this.selectedDay}
          selectAlbum={this.selectAlbum}
          toggleAlbumToolMenu={this.toggleAlbumToolMenu}
          isOpen={this.state.albumToolIsOpen}
          bulkAlbumItemsAction={this.bulkAlbumItemsAction}
        />
      </Layout>
    )
  }
}

export default withAuthenticator(DayPage, {
  includeGreetings: false,
})
