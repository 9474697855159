import AppStore from "../services/AppStore"
import ApiService from './ApiService'

const MediaService = (() => {
    const getYears = async () => {
        let years = AppStore.get("CALENDAR-YEARS")
        if (!years) {
            years = await ApiService.getYears()
            AppStore.set("CALENDAR-YEARS", years)
        }
        return years
      }
    
      const getYearDays = async year => {
        let days = AppStore.get(`CALENDAR-DAYS-${year}`)
        if (!days) {
            days = await ApiService.getYearDays(year)
            AppStore.set(`CALENDAR-DAYS-${year}`, days)
        }
        return days
      }

      const getDayMedias = async day => {
        let medias = AppStore.get(`CALENDAR-ITEMS-${day}`)
        if (!medias) {
          medias = await ApiService.getDayMedias(day)
          AppStore.set(`CALENDAR-ITEMS-${day}`, medias)
        }
        return medias
      }

      const markObject = async (bucket, key) => {
        try {
          return await ApiService.markObject(bucket, key)
        } catch (error) {
          console.error(error)
          return null
        }
      }
    
      const deleteObject = async (bucket, key) => {
        try{
          return await ApiService.deleteObject(bucket, key)
        } catch (error) {
          console.error(error)
          return null
        }
      }

    return {
        getYears,
        getYearDays,
        getDayMedias,
        markObject,
        deleteObject
    }
})()

export default MediaService
