const CalendarService = (() => {
    const getYearList = (yearsData, buckets) => {
        const yearAr = []
        yearsData = yearsData.filter(yearData => {
          const filtered = buckets.filter(
            bucket => bucket.name === yearData.bucket
          )
          return filtered.length === 1 && filtered[0].selected
        })
    
        yearsData.reduce(function(res, value) {
          if (!res[value.year]) {
            res[value.year] = { year: value.year, image_ct: 0, video_ct: 0 }
            yearAr.push(res[value.year])
          }
          res[value.year].image_ct += value.image_ct
          res[value.year].video_ct += value.video_ct
          return res
        }, {})
        return yearAr.sort((a, b) => b.year - a.year)
    }

    const getDayList = (daysData, buckets) => {
        let days = []
        if (daysData) {
          daysData.forEach(dayData => {
            const filtered = buckets.filter(
              bucket => bucket.name === dayData.bucket
            )
            if (filtered.length === 1 && filtered[0].selected) {
              if (days.filter(d => d.day === dayData.day).length === 0) {
                days.push({
                  day: dayData.day,
                  image_ct: dayData.image_ct,
                  video_ct: dayData.video_ct,
                })
              } else {
                days = days.map(d => {
                  if (d.day === dayData.day) {
                    return {
                      day: d.day,
                      image_ct: d.image_ct + dayData.image_ct,
                      video_ct: d.video_ct + dayData.video_ct,
                    }
                  }
                  return {
                    day: d.day,
                    image_ct: d.image_ct,
                    video_ct: d.video_ct,
                  }
                })
              }
            }
          })
        }
        return days.sort((a, b) => a.day - b.day)
    }

    return {
        getYearList,
        getDayList
    }
})()

export default CalendarService
