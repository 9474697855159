import React, { Component } from "react"
import AlbumService from '../services/AlbumService'

import Flatpickr from 'react-flatpickr'
import '../../node_modules/flatpickr/dist/flatpickr.css'

class AlbumForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
          selectedType: null,
          selectedDates: [],
          name: '',
          description: '',
          countryCode: '',
          private: false,
          forceErrorDisplay: false,
          dob: null,
          dobDisabled: true,
          dod: null,
          dodDisabled: true,
          errors: {
            selectedDates: '',
            name: '',
            description: '',
            countryCode: '',
            dob: '',
            dod: ''     
          }
        }
    }

    async validateName(data) {
      // get potential albumId and check if already taken
      // for 'non-private' people, name must be unique accross users 
      const albumId = await AlbumService.getAlbumId(data) 
      const filtered = this.props.albumList.filter(album => {
        if (data.selectedType === 'PEOPLE' && !data.private) {
          return album.name === data.name
        }
        return album.albumId === albumId
      })

      return ( filtered.length === 0)

    }

    async validate(updateData) {
      // console.log('AlbumForm.validate updateData', updateData)
      if (!updateData) updateData = { forceErrorDisplay: true }
      else updateData.forceErrorDisplay = false
      let state = { ...this.state, ...updateData }

      const errors = {
        selectedDates: '',
        countryCode: '',
        name: '',
        description: '',
        dob: '',
        dod: ''
      }

      if (!state.name && state.forceErrorDisplay) {
        errors.name = 'Le nom ne peut pas etre vide'
      } else {
        if (!await this.validateName(state)) {
          errors.name = 'Le nom est deja pris'
        }
      }

      if (state.selectedType === 'EVENT') {
        errors.selectedDates = (state.selectedDates.length || (!state.forceErrorDisplay && !state.selectedDates.length)) ? '' : "Vous devez selectionner une date d'album"
      }

      /*
      if (state.selectedType === 'LOCATION') {
        errors.countryCode = (LocationService.validateCountryCode(state.countryCode) || (!state.forceErrorDisplay && !state.countryCode)) ? '' : "Ce code pays n'est pas valide"
      }
      */

     if (state.selectedType === 'PEOPLE') {
      errors.dob = (!state.dobDisabled && !state.dob) ? 'Date non-valide' : ''
      errors.dod = (!state.dodDisabled && !state.dod) ? 'Date non-valide' : ''
    }

      this.setState({ ...state, errors })
      
      let errorState = false
      Object.keys(errors).forEach(key => {
        if (errors[key]) errorState = true
      })

      return !errorState
    }

    async submitData() {
      if (!await this.validate()) return

      const albumData = this.state
      if (albumData.selectedType === 'OTHER') albumData.private = true
      if (albumData.selectedType === 'EVENTS') albumData.private = false

      this.props.createAlbum(albumData)
    }

    componentDidMount() {
      // console.log('this.props', this.props)
      // this.props.albumList -> list of current albums for validation
    }

    async selectType(evt) {
      await this.validate({
        selectedType: evt.target.value
      })
    }

    async setPrivate(evt) {
      await this.validate({
        private: !this.state.private
      })
    }

    async setCountry(evt) {
      await this.validate({
        countryCode: evt.target.value
      })
    }

    async setNameText(evt) {
      await this.validate({
        name: evt.target.value
      })
    }

    async setDescription(evt) {
      await this.validate({
        description: evt.target.value
      })
    }

    async updateDate(selectedDates) {
      await this.validate({
        selectedDates
      })
    }

    async updateDOB(selectedDates) {
      await this.validate({
        dob: selectedDates.length ? selectedDates[0] : null
      })
    }
  
    async updateDOD(selectedDates) {
      await this.validate({
        dod: selectedDates.length ? selectedDates[0] : null
      })
    }
  
    async inputDOB(DOBStatus) {
      await this.validate({
        dob: DOBStatus ? null : this.state.dob,
        dobDisabled: DOBStatus 
      })
    }
  
    async inputDOD(DODStatus) {
      await this.validate({
        dod: DODStatus ? null : this.state.dod,
        dodDisabled: DODStatus 
      })
    }

    render () {
      const types = AlbumService.getAlbumTypes()
      const displayTypes = []
      if (this.state.selectedType) {
        displayTypes.push(types.filter(type => type.code === this.state.selectedType)[0])
      } else {
        displayTypes.push({ type: '', name: "Selectionnez le type d'album", icon: '' })
      }
      types.forEach(type => {
        if (type.code !== this.state.selectedType) displayTypes.push(type)
      })

      let today = new Date();
      let beginning = new Date(1900, 0, 1);

      let albumNameForm = []

      if (this.state.selectedType) {
        albumNameForm.push(<div className="field" key='field_name'>
            <label className="label new-album-label">Nom</label>
            <div className="control">
              <input className="input" type="text" placeholder="Nom de l'album" onChange={(evt) => this.setNameText(evt)} />
            </div>
              <p className="help is-danger">{this.state.errors.name}</p>
          </div>)
      }

      if (this.state.selectedType === 'PEOPLE') {
        let today = new Date();
        /* Date of Birth */
        let dobInput = <input key="field_dob" className="input" placeholder="Date de naissance" disabled />
        if (!this.state.dobDisabled) {
          dobInput = <Flatpickr 
              key="field_dob"
              onChange={selectedDates => this.updateDOB(selectedDates)} 
              className='input'
              placeholder="Date de naissance"
              options={{
                maxDate: today,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
              }}
              maxdate={today}
              dateformat='J M Y'
            />
        }
        /* Date of Decease */
        let dodInput = <input key="field_dod" className="input" placeholder="Date de décès" disabled />
        if (!this.state.dodDisabled) {
          dodInput = <Flatpickr 
              key="field_dod"
              onChange={selectedDates => this.updateDOD(selectedDates)} 
              className='input'
              placeholder="Date de décès"
              options={{
                maxDate: today,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
              }}
              maxdate={today}
              dateformat='J M Y'
            />
        }
        
        albumNameForm.push(<div key='field_private' className="field checkbox-wrapper">
            <input
              type="checkbox"
              className="cst-checkbox"
              checked={this.state.private}
              readOnly
            />
            <label className={`cst-checkbox-label no-select new-album-label ${
                this.state.private ? "selected" : ""
              }`}
              onClick={(evt) => this.setPrivate(evt)}
              role="button"
            >
              <div>non-membre de la famille</div>
            </label>
          </div>
          )

        albumNameForm.push(<div className="field" key='field_dob'>
            <label className="label new-album-label">Date de Naissance</label>
            <div className="control people-date-control">
              <div className="people-date-control-wrap">
                <input type="checkbox" onChange={(evt) => this.inputDOB(!evt.target.checked)} className="people-date-control-checkbox" key="field_dob_disabled" id="field_dob_disabled" checked={!this.state.dobDisabled} />
                <label htmlFor="field_dob_disabled" className="people-date-control-label"> </label>
              </div>
              {dobInput}
            </div>
              <p className="help is-danger">{this.state.errors.dob}</p>
          </div>)
        albumNameForm.push(<div className="field" key='field_dod'>
          <label className="label new-album-label">Date de Décès</label>
          <div className="control people-date-control">
            <div className="people-date-control-wrap">
              <input type="checkbox" onChange={(evt) => this.inputDOD(!evt.target.checked)} className="people-date-control-checkbox" key="field_dod_disabled" id="field_dod_disabled" checked={!this.state.dodDisabled} />
              <label htmlFor="field_dod_disabled" className="people-date-control-label"></label>
            </div>
            {dodInput}
          </div>
            <p className="help is-danger">{this.state.errors.dod}</p>
        </div>)  


      }
      if (this.state.selectedType === 'EVENT') {
        albumNameForm.push(<div className="field" key='field_date'>
          <label className="label new-album-label">Date</label>
          <div className="control">
            <Flatpickr 
              onChange={selectedDates => this.updateDate(selectedDates)} 
              className='input'
              placeholder="Date de l'album"
              options={{
                mode: 'range',
                minDate: beginning,
                maxDate: today,
                defaultDate: this.state.selectedDates,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
              }}
              mindate={beginning}
              maxdate={today}
              defaultdate={today}
              dateformat='J M Y'
            />
          </div>
            <p className="help is-danger">{this.state.errors.selectedDates}</p>
        </div>)
      }

      /*
      if (this.state.selectedType === 'LOCATION') {
        albumNameForm.push(<div className="field" key='field_country'>
          <label className="label new-album-label">Code Pays</label>
          <div className="control">
            <input className="input" type="text" placeholder="Code Pays" onChange={(evt) => this.setCountry(evt)} />
          </div>
            <p className="help is-danger">{this.state.errors.countryCode}</p>
        </div>)
      }
      */

      if (this.state.selectedType) {
        albumNameForm.push(<div className="field" key='field_description'>
            <label className="label new-album-label">Description</label>
            <div className="control">
              <textarea className="textarea" placeholder="Description" onChange={(evt) => this.setDescription(evt)} ></textarea>
            </div>
          </div>)
        albumNameForm.push(<div className="field" key='field_submit'>
            <div className="control">
              <button className="button" onClick={() => this.submitData()}>Creer l'album</button>
            </div>
          </div>)
      }

      return (
        <>
          <h2 key='field_title'>Créer un nouvel album</h2>

          <div className="field" key='field_type'>
            <label className="label new-album-label">Type</label>
            <div className="control">
              <div className="select">
                <select onChange={(evt) => this.selectType(evt)} value={!this.state.selectedType ? '' : this.state.selectedType}>
                  {displayTypes.map(type => <option value={type.code} key={`opt_${type.code}`}>{type.name}</option>)}
                </select>
              </div>
            </div>
          </div>

          {albumNameForm}

        </>
      )
    }
}

export default AlbumForm
