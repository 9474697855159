import React, { Component } from "react"
// import { Link } from "gatsby"
import loadingIcon from "../images/loader.svg"
// import editIcon from "../images/edit.png"
import editIcon from "../images/arrow-right.png"

import backIcon from "../images/asset-delete.png"
// import iconNewAlbum from "../images/menu/menu_new.png"
import AlbumService from "../services/AlbumService"
import UserService from "../services/UserService"
import DisplayService from '../services/DisplayService'
import BucketService from "../services/BucketService"
import Portal from './portal'
import AlbumForm from './album-form'
import './album-selector.css'

// import Resizable from '../../node_modules/Resizable';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css'
import AppStore from "../services/AppStore"
import { Scrollbars } from 'react-custom-scrollbars'

class AlbumSelector extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.createAlbum = this.createAlbum.bind(this)
    this.renderThumb = this.renderThumb.bind(this)

    this.state = {
      isLoading: true,
      isEditing: false,
      albumList: [],
      newAlbumPortal: false,
      albumListScrollHeight: 200
    }
  }

  async componentDidMount() {
      this._isMounted = true
      this.albumTypes = AlbumService.getAlbumTypes()
      const albumListData = await AlbumService.getAlbums()
      this.userInfo = await UserService.getUserInfo()
      let albumListScrollHeight = AppStore.get(`ALBUM-TOOL-HEIGHT`)
      if (!albumListScrollHeight) albumListScrollHeight = 200
      
      if (!this._isMounted) {
        return null
      }
      return this.setState({
        isLoading: false,
        albumList: albumListData,
        albumListScrollHeight
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  openPortal () {
    this.setState({
      newAlbumPortal: true
    })
  }

  closePortal () {
    this.setState({
      newAlbumPortal: false
    })
  }

  async createAlbum(album) {
    // console.log('albumList before', this.state.albumList)
    // console.log('album', album)

    let albumData = await AlbumService.createAlbumInfo(album)
    // console.log('albumList after', albumData.albumList)
    this.setState({
      albumList: albumData.albumList,
      newAlbumPortal: false
    })
    if (this.props.galleryMode) {
      // return        
    }
    this.props.selectAlbum(albumData.newAlbumId)
    return
  }

  renderThumb ({ style, ...props }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: 'rgba(0, 164, 0, 0.4)'
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  render () {

    let portalContent = ''
    if (this.state.newAlbumPortal) {
      portalContent = <Portal>
        <div className="modal">
          <div className="modal-background"></div>
            <div className="modal-content new-album">
              <AlbumForm 
                albumList={this.state.albumList}
                createAlbum={this.createAlbum} />
              <button className="modal-close is-large" aria-label="close" onClick={() => this.closePortal()}></button>
            </div>
          </div>
        </Portal>
    }


    
    let heading = ""
    let footer = ""
    const headingBtns = []
    let content = ""
    let newEntryBtn = ''
    let isResizeable = true
    if (this.state.isLoading || !this.props.selectedDay) {
      content = (
        <div className="loader">
          <img src={loadingIcon} alt="loading" />
        </div>
      )
    } else {
      if (this.props.galleryMode) {

        /**************************
         ****** GALLERY MODE ******
         **************************/
        
         if (!this.props.selectedAlbums.length && !this.state.isEditing) {
          // display list of actually selected albums
          heading = 'Albums référencés:'
          headingBtns.push(<button key='btn_UPDATE' onClick={() => this.setState({ isEditing: true })}><img src={editIcon} alt="Modifie" /></button>)
          content = <div className="">Ce media n'est pas référencé</div>

        } else {
          // display list of albums that can be selected, highlighting the currently selectted ones
          
          if (!this.state.isEditing) {
            heading = 'Albums référencés:'
            headingBtns.push(<button key='btn_UPDATE' onClick={() => this.setState({ isEditing: true })}><img src={editIcon} alt="Modifie" /></button>)
          } else {
            heading = 'Sélectionnez le/les albums dans la liste ou '
            headingBtns.push(<button key='btn_CANCEL' onClick={() => this.setState({ isEditing: false })}><img src={backIcon} alt="Annule" /></button>)
            newEntryBtn = <button className='is-primary' onClick={() => this.openPortal()}>
              Créer un album
            </button>
          }

          // sort helper
          const typeSort = ['EVENT', 'OTHER', 'PEOPLE']
          
          content = this.state.albumList.filter(album => {
            if (this.state.isEditing) {
              let isDisabled = false
              // if (album.private !== '' && album.private !== this.userInfo.attributes['custom:bucket-name'] && album.private !== this.userInfo.username) isDisabled = true
              // if (!album.allow_edit && album.albumId.indexOf(`${this.userInfo.username}|`) !== 0) isDisabled = true // -> not sure yet?

              if (album.type === 'EVENT') {
                if (new Date(album.startDate).getTime() > DisplayService.fromYYYYMMDD(this.props.selectedDay).getTime()
                  || new Date(album.endDate).getTime() < DisplayService.fromYYYYMMDD(this.props.selectedDay).getTime())
                  isDisabled = true
              }
              if (album.type === 'OTHER') {
                if (album.private !== this.userInfo.attributes['custom:bucket-name'] && album.private !== this.userInfo.username) isDisabled = true
              }
              return !isDisabled
            }
            // in non-edit mode
            if (this.props.selectedAlbums.indexOf(album.albumId) > -1) return true
            return false
          }).sort((a, b) => {
            // sort by type first
            if (typeSort.indexOf(a.type) > typeSort.indexOf(b.type)) return 1
            if (typeSort.indexOf(a.type) < typeSort.indexOf(b.type)) return -1
            
            // same type -> sort by name
            return a.name.localeCompare(b.name);
          }).map(album => {
            let iconSrc = this.albumTypes.filter(alb => alb.code === album.type)[0].icon
            let userDetail = album.private ? <span className='album-selector-item-user'>({BucketService.getBucketInfo(album.private).display})</span> : []
            if (album.type === 'PEOPLE' && album.private === '') {
              userDetail = []
            }

            if (this.state.isEditing) {
              return <div
                key={`wrap-${encodeURIComponent(album.albumId)}`}
                className="checkbox-wrapper js-checkbox"
                >
                <input
                    key={`inp-${encodeURIComponent(album.albumId)}`}
                    type="checkbox"
                    className="cst-checkbox"
                    checked={(this.props.selectedAlbums.indexOf(album.albumId) > -1)}
                    readOnly
                  />
                  <label
                    key={`lbl-${encodeURIComponent(album.albumId)}`}
                    className={`cst-checkbox-label cst-right no-select ${
                      (this.props.selectedAlbums.indexOf(album.albumId) > -1) ? "selected" : ""
                    }`}
                    onClick={()=> this.props.selectAlbum(album.albumId)}
                    role="button"
                  >
                    <div className='album-selector-item-wrap'>
                      <div className='album-selector-item'>
                        {album.name} {userDetail}
                        </div>
                      <div className='album-selector-item-icon'>
                        <img src={iconSrc} alt={album.type} />
                      </div>
                    </div>
                  </label>
                </div>
            }
            // in non-edit mode
            return <a 
                key={`dv_${album.albumId}`}
                href={`/album/${album.albumId}`}
                title="Ouvrir l'album dans un autre onglet"
                target='_blank'
                style={{
                  color: 'initial',
                  textDecoration: 'none'
                }}
              >
                <div className='album-selector-item-wrap'>
                  <div className='album-selector-item'>
                    {album.name} {userDetail}
                    </div>
                  <div className='album-selector-item-icon'>
                    <img src={iconSrc} alt={album.type} />
                  </div>
                </div>
              </a>
          })
        }
      } else {
        
        /**************************
         ****** POLAROID MODE *****
         **************************/
        
         if (this.props.selectedAlbums.length) {

          /* display selected album */

          isResizeable = false
          heading = 'Album Sélectionné:'
          const itemCount = this.props.itemList.filter(item => item.albumSelection).length
          let label = ''
          if (!itemCount) {
            label = 'pas de média sur cette page'
          } else {
            label = `${itemCount} médias sur cette page`
          }
          footer = <div style={{ padding: '0 .25rem .25rem'}}>
              <div style={{ fontSize: '.9rem', marginBottom: '.125rem' }}>({label})</div>
              <div><button className='is-primary' onClick={this.props.bulkAlbumItemsAction} disabled={((this.props.itemList.length - itemCount) === 0)}>Tout sélectionner</button></div>
            </div>
          headingBtns.push(<button key='btn_UPDATE' onClick={()=> this.props.selectAlbum()}>
              <img src={editIcon} alt="Modifie" />
            </button>)
          content = this.state.albumList.filter(album => album.albumId === this.props.selectedAlbums[0])
            .map(album => {
              let iconSrc = this.albumTypes.filter(alb => alb.code === album.type)[0].icon
              let userDetail = album.private ? <span className='album-selector-item-user'>({BucketService.getBucketInfo(album.private).display})</span> : []
              return <div className='album-selector-item-wrap' key={`wrap-${encodeURIComponent(album.albumId)}`}>
                  <div className='album-selector-item'>
                    {album.name} {userDetail}
                    </div>
                  <div className='album-selector-item-icon'>
                    <img src={iconSrc} alt={album.type} />
                  </div>
                </div>
            })
        } else {

          /* display list of possible albums to select from */

          heading = "Sélectionnez l'album que vous désirez modifier - ou "

          // sort helper
          const typeSort = ['EVENT', 'OTHER', 'PEOPLE']

          headingBtns.push(<button key='btn_UPDATE' onClick={()=> this.props.toggleAlbumToolMenu()}>
              <img src={backIcon} alt="Modifie" />
            </button>)

          content = this.state.albumList.filter(album => {
            //console.log('**album**', album)
            // filtering out albums that are not relevant
            if (album.type === 'EVENT') {
              if (new Date(album.startDate).getTime() > DisplayService.fromYYYYMMDD(this.props.selectedDay).getTime()
                || new Date(album.endDate).getTime() < DisplayService.fromYYYYMMDD(this.props.selectedDay).getTime())
                return false
            }
            if (album.type === 'OTHER') {
              if (album.private !== this.userInfo.attributes['custom:bucket-name'] && album.private !== this.userInfo.username) return false
            }
            return true
          }).sort((a, b) => {
            // sort by type first
            if (typeSort.indexOf(a.type) > typeSort.indexOf(b.type)) return 1
            if (typeSort.indexOf(a.type) < typeSort.indexOf(b.type)) return -1
            
            // same type -> sort by name
            return a.name.localeCompare(b.name);
          }).map(album => {
            let iconSrc = this.albumTypes.filter(alb => alb.code === album.type)[0].icon
            let userDetail = album.private ? <span className='album-selector-item-user'>({BucketService.getBucketInfo(album.private).display})</span> : []
            if (album.type === 'PEOPLE' && album.private === '') {
              userDetail = []
            }
            return <button
              key={`btn_${album.albumId}`}
              onClick={()=> this.props.selectAlbum(album.albumId)}
            >
                <div className='album-selector-item-wrap'>
                  <div className='album-selector-item'>
                    {album.name} {userDetail}
                    </div>
                  <div className='album-selector-item-icon'>
                    <img src={iconSrc} alt={album.type} />
                  </div>
                </div>
              </button>
          })

          newEntryBtn = <button className='is-primary' onClick={() => this.openPortal()}>
              Créer un album
            </button>

        }
      }
    }

    // headingBtns.push(<button key='btn_NEW' onClick={() => this.openPortal()}>NEW</button>)
    // headingBtns.push(<button key='btn_CANCEL' onClick={()=> this.props.toggleAlbumToolMenu()}>CANCEL</button>)
    let display = null
    if (isResizeable) {
      display = <ResizableBox 
          className="box" width={285} height={this.state.albumListScrollHeight} axis="y"
          onResize={(e, data) => this.setState({ albumListScrollHeight: data.size.height })}
          onResizeStop={(e, data) => {
            this.setState({ albumListScrollHeight: data.size.height })
            AppStore.set(`ALBUM-TOOL-HEIGHT`, data.size.height)
          }}
        >
          <Scrollbars 
            style={{ width: '100%', height: this.state.albumListScrollHeight }}
            renderThumbVertical={this.renderThumb}
            >
            {content}
          </Scrollbars>
        </ResizableBox>
    } else {
      display = <>
          {content}
        </>
    }

    return <>
      <div className="album-selector-wrapper">
        <div className="album-selector-edit">
          {headingBtns}
        </div>
        <div>
          {heading}
          {newEntryBtn}
        </div>
      </div>
      <div className="album-selector-list">
        {display}     
      </div>
      {footer}
      {portalContent}
    </>
  }
}

export default AlbumSelector
