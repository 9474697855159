import React, { Component } from "react"
import menuIcon from "../images/menu/menu_filter.png"
import "./bucket-selector.css"

class BucketSelector extends Component {
  constructor(props) {
    super()
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)

    this.state = {
      isOpen: false,
    }
  }

  handleCheckboxChange(evt) {
    this.props.handleBucketChange(evt)
  }

  toggleMenu() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const buckets = JSON.parse(this.props.buckets)
    return (
      <React.Fragment>
        <div className="bucket-menu">
          <button onClick={this.toggleMenu}>
            <img src={menuIcon} alt="filtering" />
          </button>
        </div>
        <div className={`bucket-selector-action-layer ${this.state.isOpen ? "is-open" : ""}`} onClick={this.toggleMenu}></div>
        <div
          className={`bucket-selector ${this.state.isOpen ? "is-open" : ""}`}
        >
          {buckets.map(bucket => {
            let sub = ""
            if (bucket.displaySub)
              sub = <div style={{ fontSize: ".8rem" }}>{bucket.displaySub}</div>

            return (
              <div
                key={`wrap-${bucket.name}`}
                className="checkbox-wrapper js-checkbox"
              >
                <input
                  key={`inp-${bucket.name}`}
                  type="checkbox"
                  className="cst-checkbox"
                  checked={bucket.selected}
                  readOnly
                />
                <label
                  key={`lbl-${bucket.name}`}
                  className={`cst-checkbox-label no-select ${
                    bucket.selected ? "selected" : ""
                  }`}
                  data-name={bucket.name}
                  onClick={this.handleCheckboxChange}
                  role="button"
                >
                  <div>{bucket.display}</div>
                  {sub}
                </label>
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default BucketSelector
